import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const { isNavigationFailure, NavigationFailureType } = Router;

const original_push = Router.prototype.push;
Router.prototype.push = function push(location) {
    original_push.call(this, location).catch(error => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error)
        }
    })
};

// Containers
const DefaultContainer = () =>
    import (
        /* webpackChunkName: "defaulcontainer" */
        "@/containers/DefaultContainer"
    );
const EmptyContainer = () =>
    import (
        /* webpackChunkName: "emptyContainer" */
        "@/containers/EmptyContainer"
    );
const DefaultContainerMerchant = () =>
    import (
        /* webpackChunkName: "mdefaulcontainer" */
        "@/containers/DefaultContainerMerchant"
    );
const AuthContainer = () =>
    import ( /* webpackChunkName: "authcontainer" */ "./containers/AuthContainer");
const ContainerWithOnlyNavBar = () =>
    import (
        /* webpackChunkName: "onlynavcontainer" */
        "./containers/ContainerWithOnlyNavBar.vue"
    );
const ContainerForMerchantRegistraion = () =>
    import (
        /* webpackChunkName: "mregistercontainer" */
        "./containers/ContainerForMerchantRegistraion.vue"
    );
const AuthDeliverContainer = () =>
    import (
        /* webpackChunkName: "authdelivercontainer" */
        "./containers/AuthDeliverContainer"
    );
const AuthResetPassContainer = () =>
    import (
        /* webpackChunkName: "authresetcontainer" */
        "./containers/AuthResetPassContainer"
    );
const DefaultContainerDeliver = () =>
    import (
        /* webpackChunkName: "ddefaulcontainer" */
        "@/containers/DefaultContainerDeliver"
    );
const ContainerForMessages = () =>
    import (
        /* webpackChunkName: "messagecontainer" */
        "@/containers/ContainerForMessages"
    );

// sucsess and error pages -- *****************************************************************
const SuccessPage = () =>
    import ( /* webpackChunkName: "successpage" */ "./views/SuccessPage");
const ErrorPage = () =>
    import ( /* webpackChunkName: "errorpage" */ "./views/ErrorPage");
const PasswordReset = () =>
    import ( /* webpackChunkName: "passwordreset" */ "./views/PasswordReset");
const DeliverySuccess = () =>
    import ( /* webpackChunkName: "deliverysuccess" */ "./views/DeliverySuccess");

// Buyer pages -- ******************************************************************************
import BuyerLogin from "./views/Buyer/Login";
import BuyerRegister from "./views/Buyer/Register";

const BuyerOtp = () =>
    import ( /* webpackChunkName: "bbuyerotp" */ "./views/Buyer/BuyerOtp.vue");
const BuyerProfileConfigure = () =>
    import (
        /* webpackChunkName: "bprofileconfig" */
        "./views/Buyer/Profile/ProfileConfigure"
    );
const ResetPasswordFirstPage = () =>
    import (
        /* webpackChunkName: "bresetpasswordfirstpage" */
        "./views/Buyer/ResetPasswordFirstPage"
    );

const BuyerOrders = () =>
    import ( /* webpackChunkName: "borderlist" */ "./views/Buyer/Order/OrderList");
const BuyerOrderConfigure = () =>
    import (
        /* webpackChunkName: "boderconfig" */
        "./views/Buyer/Order/OrderConfigure1"
    );

const BuyerReturnOrders = () =>
    import (
        /* webpackChunkName: "breturnorderlist" */
        "./views/Buyer/ReturnOrder/ReturnOrderList"
    );
const BuyerReturnOrdersConfigure = () =>
    import (
        /* webpackChunkName: "breturnorderconfig" */
        "./views/Buyer/ReturnOrder/ReturnOrderConfigure"
    );
const BuyerReturnOrderConfigureView = () =>
    import (
        /* webpackChunkName: "breturnorderconfigview" */
        "./views/Buyer/ReturnOrder/ReturnOrderConfigureView"
    );

const Wishlist = () =>
    import ( /* webpackChunkName: "bwhishlist" */ "./views/Buyer/Wishlist");

const BuyerHome = () =>
    import ( /* webpackChunkName: "bhome" */ "./views/Buyer/Home");
const Cart = () =>
    import ( /* webpackChunkName: "cart" */ "./views/Buyer/Cart");
const Checkout = () =>
    import ( /* webpackChunkName: "checkout" */ "./views/Buyer/Checkout");
const Product = () =>
    import ( /* webpackChunkName: "bproduct" */ "./views/Buyer/Product.vue");
const Productlisthometabs = () =>
    import (
        /* webpackChunkName: "productlisthometabs" */
        "./views/Buyer/Productlisthometabs.vue"
    );
const Productlist = () =>
    import (
        /* webpackChunkName: "bproductslist" */
        "./views/Buyer/Productlist.vue"
    );
const ProductlistMain = () =>
    import (
        /* webpackChunkName: "bproductlistmain" */
        "./views/Buyer/ProductlistMain.vue"
    );
const ProductSearch = () =>
    import (
        /* webpackChunkName: "bproductsearch" */
        "./views/Buyer/Productsearch.vue"
    );
const MadeInSrilankaProducts = () =>
    import (
        /* webpackChunkName: "bproductmadeinsrilanka" */
        "./views/Buyer/MadeInSrilankaProducts.vue"
    );
const ETeleshop = () =>
    import (
        /* webpackChunkName: "bproductETeleshop" */
        "./views/Buyer/ETeleshop.vue"
    );
const Brandsearch = () =>
    import (
        /* webpackChunkName: "bbrandsearch" */
        "./views/Buyer/Brandsearch.vue"
    );
const StoreProduct = () =>
    import (
        /* webpackChunkName: "bstoreproduct" */
        "./views/Buyer/StoreProduct.vue"
    );
const FlashDeal = () =>
    import ( /* webpackChunkName: "bflashdeal" */ "./views/Buyer/FlashDeal.vue");
const TermsAndConditions = () =>
    import (
        /* webpackChunkName: "btermsandconditions" */
        "./views/TermsAndConditions.vue"
    );
const PrivacyPolicy = () =>
    import ( /* webpackChunkName: "privacypolicy" */ "./views/PrivacyPolicy.vue");
const Category = () =>
    import ( /* webpackChunkName: "bcategory" */ "./views/Buyer/Category.vue");
const Brand = () =>
    import ( /* webpackChunkName: "bbrand" */ "./views/Buyer/Brand.vue");
const CouponList = () =>
    import (
        /* webpackChunkName: "CouponList" */
        "./views/Buyer/Coupon/CouponList.vue"
    );
const ProductlistCoupon = () =>
    import (
        /* webpackChunkName: "bproductlistcoupon" */
        "./views/Buyer/ProductlistCoupon"
    );
// My Reviews -----------------------------------------------
const MyReviews = () =>
    import (
        /* webpackChunkName: "bmyreviews" */
        "./views/Buyer/MyReviews/MyReviews"
    );

// Mercant pages -- ****************************************************************************
// import MerchantLogin from './views/Merchant/Login'
const ResetPasswordFirstPage2 = () =>
    import ( /* webpackChunkName: "mresetpasswordfirstpage2" */ "./views/Merchant/ResetPasswordFirstPage2");
// const ResetPasswordFirstPage2New = () =>
//   import(/* webpackChunkName: "mresetpasswordfirstpage2" */ "./views/Merchant/ResetPasswordFirstPage2New"
//   );
const MerchantRegister = () =>
    import ( /* webpackChunkName: "mregister" */ "./views/Merchant/Register");
const MerchantRegisterV1 = () =>
    import (
        /* webpackChunkName: "mmerchantregitser" */
        "./views/Merchant/Register/MerchantRegister.vue"
    );
const MerchantRegisterStep2 = () =>
    import (
        /* webpackChunkName: "mmerchantregisterstep" */
        "./views/Merchant/Register/MerchantRegisterStep2.vue"
    );

// Merchant mobile verify
const MerchantProfileMobileVerify = () =>
    import (
        /* webpackChunkName: "mmerchantregisterstep" */
        "./views/Merchant/Register/MerchantProfileMobileVerify.vue"
    );
const MerchantGuidelines = () =>
    import (
        /* webpackChunkName: "mmerchantregisterstep" */
        "./views/Merchant/Product/guidelines.vue"
    );
const MerchantOTP = () =>
    import ( /* webpackChunkName: "mmerchantotp" */ "./views/Merchant/MerchantOtp");
const MerchantProfileConfigure = () =>
    import (
        /* webpackChunkName: "mprofileconfig" */
        "./views/Merchant/Profile/ProfileConfigure"
    );

// import MerchantLanding from './views/Merchant/Landing'
const MerchantLandingNew = () =>
    import ( /* webpackChunkName: "mlandingnew" */ "./views/Merchant/LandingNew");
// const MerchantLanding = () =>
//   import(/* webpackChunkName: "mlandingnew" */ "./views/Merchant/Landing-new");
const MerchantSuccess = () =>
    import ( /* webpackChunkName: "mlandingnew" */ "./views/Merchant/MerchantSuccess");



const MerchantDashbord = () =>
    import ( /* webpackChunkName: "mdashboard" */ "./views/Merchant/Dashbord");
const MerchantApproveRejectOrder = () =>
    import (
        /* webpackChunkName: "mmerchantapproverejectorder" */
        "./views/Merchant/MerchantApproveRejectOrder"
    );
const MerchantNotificationCenter = () =>
    import (
        /* webpackChunkName: "mmerchantnotificationcenter" */
        "./views/Merchant/MerchantNotificationCenter"
    );
const VisitorsChartDetail = () =>
    import (
        /* webpackChunkName: "mvisitorschartdetails" */
        "./views/Merchant/dashbord/VisitorsChartDetail.vue"
    );
const ReturningCustomerDetail = () =>
    import (
        /* webpackChunkName: "mreturningcustomerdetails" */
        "./views/Merchant/dashbord/ReturningCustomerDetail.vue"
    );
const AvgOrderDetail = () =>
    import (
        /* webpackChunkName: "mavgorderdetails" */
        "./views/Merchant/dashbord/AvgOrderDetail.vue"
    );
const ConversionRateDetail = () =>
    import (
        /* webpackChunkName: "mconversionratedetail" */
        "./views/Merchant/dashbord/ConversionRateDetail.vue"
    );
const GalleryList = () =>
    import (
        /* webpackChunkName: "mgalarylistc" */
        "./views/Merchant/Gallery/GalleryList.vue"
    );

// store pages --------------------------------------------
const StoreList = () =>
    import (
        /* webpackChunkName: "mstorelist" */
        "./views/Merchant/Store/StoreList"
    );
const StoreConfigure = () =>
    import (
        /* webpackChunkName: "mstoreconfig" */
        "./views/Merchant/Store/StoreConfigure"
    );
const StoreConfigureHolidayMode = () =>
    import (
        /* webpackChunkName: "mstoreconfigureholidaymode" */
        "./views/Merchant/Store/StoreConfigureHolidayMode"
    );
const StoreBanner = () =>
    import (
        /* webpackChunkName: "mstorebanner" */
        "./views/Merchant/Store/StoreBanner"
    );

// Order pages --------------------------------------------
const OrderList = () =>
    import (
        /* webpackChunkName: "morderlist" */
        "./views/Merchant/Order/OrderList"
    );
const OrderListGroup = () =>
    import (
        /* webpackChunkName: "morderlistgroup" */
        "./views/Merchant/Order/OrderListGroup"
    );
const OrderConfigure = () =>
    import (
        /* webpackChunkName: "morderconfig" */
        "./views/Merchant/Order/OrderConfigure"
    );
const OrderLines = () =>
    import (
        /* webpackChunkName: "morderlines" */
        "./views/Merchant/Order/OrderLines"
    );
const PendingOrderLines = () =>
    import (
        /* webpackChunkName: "mpendingorderlines" */
        "./views/Merchant/Order/PendingOrderLines"
    );
const MerchantCancelledOrder = () =>
    import (
        /* webpackChunkName: "mcancelledorder" */
        "./views/Merchant/Order/MerchantCancelledOrder"
    );
const CancelledOrderLines = () =>
    import (
        /* webpackChunkName: "mcancelledordderlines" */
        "./views/Merchant/Order/CancelledOrderLines"
    );

const MerchantReportList = () =>
    import (
        /* webpackChunkName: "merchantreportlist" */
        "./views/Merchant/Report/MerchantReportList"
    );

// dispute
const DisputeList = () =>
    import (
        /* webpackChunkName: "mdisputelist" */
        "./views/Merchant/DisputeManagement/DisputeList"
    );

const MerchantDisputeLines = () =>
    import (
        /* webpackChunkName: "mdisputeline" */
        "./views/Merchant/DisputeManagement/MerchantDisputeLines"
    );

// Promotions pages -----------------------------------------
const PromotionsList = () =>
    import (
        /* webpackChunkName: "mpromotionslist" */
        "./views/Merchant/Promotions/PromotionsList"
    );
const PromotionConfigureToProduct = () =>
    import (
        /* webpackChunkName: "mpromotionconfiguretoproduct" */
        "./views/Merchant/Promotions/PromotionConfigureToProduct"
    );

// product pages --------------------------------------------
const ProductList = () =>
    import (
        /* webpackChunkName: "mproductlist" */
        "./views/Merchant/Product/ProductList"
    );
const HomePageViewAllProdutsList = () =>
    import (
        /* webpackChunkName: "mhomepageviewallprodutslist" */
        "./views/Merchant/Product/HomePageViewAllProdutsList"
    );
const ProductConfigure = () =>
    import (
        /* webpackChunkName: "mproductconfig" */
        "./views/Merchant/Product/ProductConfigure"
    );
const ProductConfigureView = () =>
    import (
        /* webpackChunkName: "mproductconfigview" */
        "./views/Merchant/Product/ProductConfigureView"
    );
const ProductConfigureBulk = () =>
    import (
        /* webpackChunkName: "mproductconfigbulk" */
        "./views/Merchant/Product/ProductConfigureBulk"
    );
const StockConfigureBulk = () =>
    import (
        /* webpackChunkName: "mstockconfigbulk" */
        "./views/Merchant/Product/StockConfigureBulk"
    );
const StockConfigureBulkSub = () =>
    import (
        /* webpackChunkName: "mstockconfigbulksub" */
        "./views/Merchant/Product/StockConfigureBulkSub"
    );
const StockConfigureFreeshippingBulk = () =>
    import (
        /* webpackChunkName: "mstockconfigbulksub" */
        "./views/Merchant/Product/StockConfigureFreeshippingBulk"
    );
// Return order ---------------------------------------------
const MerchantReturnOrders = () =>
    import (
        /* webpackChunkName: "mreturnorderlist" */
        "./views/Merchant/ReturnOrder/ReturnOrderList"
    );
const MerchantReturnOrdersConfigure = () =>
    import (
        /* webpackChunkName: "mretunrorderconfig" */
        "./views/Merchant/ReturnOrder/ReturnOrderConfigure"
    );

// Prefreances ----------------------------------------------
const preferences = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Merchant/Preferences/mPreferences"
    );

// Delivery Partner -- ****************************************************************************
const ResetPasswordFirstPage3 = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/ResetPasswordFirstPage3"
    );
const deliverLogin = () =>
    import ( /* webpackChunkName: "mpreference" */ "./views/Deliver/Login");
const DeliverNewLogin = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/DeliverLogin/DeliverLogin.vue"
    );
const deliverRegister = () =>
    import ( /* webpackChunkName: "mpreference" */ "./views/Deliver/Register");
const DeliverProfileConfigure = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Profile/ProfileConfigure"
    );
const DeliverDashbord = () =>
    import ( /* webpackChunkName: "mpreference" */ "./views/Deliver/Dashbord");
const DeliverRates = () =>
    import ( /* webpackChunkName: "mpreference" */ "./views/Deliver/Rates");
const PiskupAddressList = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/PickupAddress/PiskupAddressList"
    );
const PickupAddressCreate = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/PickupAddress/PickupAddressCreate"
    );
const DeliverOrderList = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Order/OrderList"
    );
const DeliverOrderListGroup = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Order/OrderListGroup"
    );
const DeliverOrderLines = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Order/DeliverOrderLines"
    );
const DeliverOrderDetails = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Order/OrderDetails"
    );
const DeliverRateConfigure = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Rates/DeliverRateConfigure"
    );
const DeliverRateConfigureNew = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Rates/DeliverRateConfigureNew.vue"
    );
const DeliverZoneList = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/zones/DeliverZoneList.vue"
    );
const DeliverOtp = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/DeliverOtp.vue"
    );
const DeliveryDispatchConfigure = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Dispatch/DeliveryDispatchConfigure"
    );
const DeliverReturnOrders = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/ReturnOrder/ReturnOrderList"
    );
const DeliverReturnOrdersConfigure = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/ReturnOrder/ReturnOrderConfigure"
    );
const DeliverDispatch = () =>
    import (
        /* webpackChunkName: "mpreference" */
        "./views/Deliver/Dispatch/DeliverDispatch"
    );

const TestPage = () =>
    import ( /* webpackChunkName: "mtestpage" */ "./views/Merchant/TestPage");

// comman -------------------------------------
const ContactUs = () =>
    import ( /* webpackChunkName: "comcontactus" */ "./views/comman/ContactUs.vue");
const AboutUs = () =>
    import ( /* webpackChunkName: "comaboutus" */ "./views/comman/AboutUs.vue");
const Subscribe = () =>
    import ( /* webpackChunkName: "mtestpage" */ "./views/comman/Subscribe.vue");
const ComingSoon = () =>
    import ( /* webpackChunkName: "mtestpage" */ "./views/comman/ComingSoon.vue");
// Voucher -------------------------------------
const VoucherList = () =>
    import (
        /* webpackChunkName: "VoucherList" */
        "./views/Buyer/Voucher/VoucherList.vue"
    );
Vue.use(Router);

export default new Router({
    mode: "history",
    // mode: 'hash',
    // base: process.env.BASE_URL,
    scrollBehavior: () => ({
        y: 0,
    }),
    routes: [{
            path: "/buyerHome",
            redirect: "/buyerHome",
            component: DefaultContainer,
            // -- Buyer Routs -- ***************************************************************************
            children: [{
                    path: "/buyerHome",
                    name: "Buyer Home",
                    component: BuyerHome,
                },
                {
                    path: "/buyer-login",
                    name: "buyer login",
                    component: BuyerLogin,
                },
                {
                    path: "/buyer-register",
                    name: "buyer register",
                    component: BuyerRegister,
                },
                {
                    path: "/buyer-otp",
                    name: "Buyer OTP",
                    component: BuyerOtp,
                },
                {
                    path: "/buyer-profile",
                    name: "Buyer Profile",
                    component: BuyerProfileConfigure,
                },
                {
                    path: "/buyer-orders",
                    name: "Buyer Orders",
                    component: BuyerOrders,
                },
                {
                    path: "/buyer-order-configure/:id",
                    name: "Buyer Order Configure",
                    component: BuyerOrderConfigure,
                },
                {
                    path: "/buyer-return-orders",
                    name: "Buyer Return Orders",
                    component: BuyerReturnOrders,
                },
                {
                    path: "/buyer-return-order-configure/:id",
                    name: "Buyer Return Order Configure",
                    component: BuyerReturnOrdersConfigure,
                },
                {
                    path: "/buyer-return-order-configure-view/:id",
                    name: "Buyer Return Order Configure View",
                    component: BuyerReturnOrderConfigureView,
                },
                {
                    path: "/buyer-cart",
                    name: "Buyer Cart",
                    component: Cart,
                },
                {
                    path: "/buyer-checkout",
                    name: "Buyer Checkout",
                    component: Checkout,
                },
                {
                    path: "/buyer-coupons",
                    name: "Coupon List",
                    component: CouponList,
                },
                {
                    path: "/buyer-vouchers",
                    name: "Voucher List",
                    component: VoucherList,
                },
                {
                    path: "/buyer-wishlist",
                    name: "Buyer Wishlist",
                    component: Wishlist,
                },
                {
                    path: "/productlisthometabs",
                    name: "Product List Home Tabs",
                    component: Productlisthometabs,
                },
                {
                    path: "/productlist",
                    name: "Product List",
                    component: Productlist,
                },
                {
                    path: "/productlistall",
                    name: "Product List All",
                    component: HomePageViewAllProdutsList,
                },
                {
                    path: "/productlistmain",
                    name: "Product List Main",
                    component: ProductlistMain,
                },
                {
                    path: "/productlistcoupon",
                    name: "Product List Coupon",
                    component: ProductlistCoupon,
                },
                {
                    path: "/productsearch",
                    name: "Product Search",
                    component: ProductSearch,
                },
                {
                    path: "/madeinsrilankaproducts",
                    name: "Made In Srilanka Products",
                    component: MadeInSrilankaProducts,
                },
                {
                    path: "/brandsearch",
                    name: "Brand Search",
                    component: Brandsearch,
                },
                {
                    path: "/storeProducts/:id",
                    name: "Store Product",
                    component: StoreProduct,
                },
                {
                    path: "/eTeleshop",
                    name: "E Teleshop",
                    component: ETeleshop,
                },
                {
                    path: "/product",
                    name: "product",
                    component: Product,
                    props: true,
                },
                {
                    path: "/flashDeal",
                    name: "Flash Deal",
                    component: FlashDeal,
                },
                {
                    path: "/category",
                    name: "category",
                    component: Category,
                },
                {
                    path: "/terms-and-conditions",
                    name: "TermsAndConditions",
                    component: TermsAndConditions,
                },
                {
                    path: "/privacy_policy",
                    name: "PrivacyPolicy",
                    component: PrivacyPolicy,
                },
                {
                    path: "/brand",
                    name: "Brand Items",
                    component: Brand,
                },
                {
                    path: "/buyer-my-reviews",
                    name: "Buyer My Reviews",
                    component: MyReviews,
                },
                {
                    path: "/contact-us",
                    name: "Contact Us",
                    component: ContactUs,
                },
                {
                    path: "/about-us",
                    name: "About Us",
                    component: AboutUs,
                },
                {
                    path: "/subscribe",
                    name: "Subscribe",
                    component: Subscribe,
                },
            ],
        },
        // -- comingSoon
        {
            path: "/coming-soon",
            redirect: "/coming-soon",
            component: EmptyContainer,
            children: [{
                path: "/coming-soon",
                name: "Coming Soon",
                component: ComingSoon,
            }]
        },
        // -- merchant Routs -- **************************************************************************
        {
            path: "/merchant-dashbord",
            redirect: "/merchant-dashbord",
            component: DefaultContainerMerchant,
            children: [{
                    path: "/merchant-profile",
                    name: "Merchant Profile",
                    component: MerchantProfileConfigure,
                },
                {
                    path: "/merchant-dashbord",
                    name: "Merchant Dashbord",
                    component: MerchantDashbord,
                },
                {
                    path: "/merchant-store",
                    name: "Merchant Store",
                    component: StoreList,
                },
                {
                    path: "/merchant-store-configure/:id",
                    name: "Store Configure",
                    component: StoreConfigure,
                },
                {
                    path: "/merchant-store-configure-holiday-mode/:id",
                    name: "Store Configure Holiday Mode",
                    component: StoreConfigureHolidayMode,
                },
                {
                    path: "/merchant-store-banner/:id",
                    name: "Store Banner",
                    component: StoreBanner,
                },
                {
                    path: "/merchant-promotions-list",
                    name: "Promotions List",
                    component: PromotionsList,
                },
                {
                    path: "/merchant-promotion-configure-to-product/:id",
                    name: "Promotion Configure To Product",
                    component: PromotionConfigureToProduct,
                },
                {
                    path: "/merchant-order-group",
                    name: "Order List Group",
                    component: OrderListGroup,
                },
                {
                    path: "/merchant-order-lines",
                    name: "Order Lines",
                    component: OrderLines,
                },
                {
                    path: "/merchant-order-list",
                    name: "Order List",
                    component: OrderList,
                },
                {
                    path: "/merchant-order-configure/:id",
                    name: "Order Configure",
                    component: OrderConfigure,
                },
                {
                    path: "/product-store",
                    name: "Merchant Product",
                    component: ProductList,
                },
                {
                    path: "/merchant-product-configure/:id",
                    name: "Product Configure",
                    component: ProductConfigure,
                },
                {
                    path: "/merchant-product-view/:id",
                    name: "Product Configure View",
                    component: ProductConfigureView,
                },
                {
                    path: "/merchant-report-list",
                    name: "Merchant Report List",
                    component: MerchantReportList,
                },
                {
                    path: "/merchant-product-configure-bulk",
                    name: "Product Configure Bulk",
                    component: ProductConfigureBulk,
                },
                {
                    path: "/merchant-stock-configure-bulk",
                    name: "Stock Configure Bulk",
                    component: StockConfigureBulk,
                },
                {
                    path: "/merchant-stock-configure-bulk-sub",
                    name: "Stock Configure Bulk Sub",
                    component: StockConfigureBulkSub,
                },
                {
                    path: "/merchant-stock-configure-freeshipping-bulk",
                    name: "Stock Configure Freeshipping Bulk",
                    component: StockConfigureFreeshippingBulk,
                },
                {
                    path: "/merchant-notification-center",
                    name: "Notification Center",
                    component: MerchantNotificationCenter,
                },
                {
                    path: "/merchant-pending-order",
                    name: "Merchant Approve Reject Order",
                    component: MerchantApproveRejectOrder,
                },
                {
                    path: "/merchant-cancelled-order",
                    name: "Merchant Cancelled Order",
                    component: MerchantCancelledOrder,
                },
                {
                    path: "/merchant-pending-order-lines",
                    name: "Pending Order Lines",
                    component: PendingOrderLines,
                },
                {
                    path: "/merchant-cancelled-order-lines",
                    name: "Cancelled Order Lines",
                    component: CancelledOrderLines,
                },
                {
                    path: "/merchant-return-orders",
                    name: "Merchant Return Orderst",
                    component: MerchantReturnOrders,
                },
                {
                    path: "/merchant-return-orders-configure/:id",
                    name: "Merchant Return Orders Configure",
                    component: MerchantReturnOrdersConfigure,
                },
                {
                    path: "/merchant-visitor-chart",
                    name: "Visitors Chart Detail",
                    component: VisitorsChartDetail,
                },
                {
                    path: "/merchant-visitor-returning-chart",
                    name: "Returning Customer Detail",
                    component: ReturningCustomerDetail,
                },
                {
                    path: "/merchant-avg-order-chart",
                    name: "Avg Order Detail",
                    component: AvgOrderDetail,
                },
                {
                    path: "/merchant-conversion-rate-chart",
                    name: "Conversion Rate Detail",
                    component: ConversionRateDetail,
                },
                {
                    path: "/gallery-list",
                    name: "gallery list",
                    component: GalleryList,
                },
                {
                    path: "/merchant-preferences",
                    name: "preferences",
                    component: preferences,
                },
                {
                    path: "/merchant-dispute-list",
                    name: "Merchant Dispute List",
                    component: DisputeList,
                },
                {
                    path: "/merchant-dispute-lines/:id",
                    name: "Merchant Dispute Lines",
                    component: MerchantDisputeLines,
                },
            ],
        },
        // -- merchant Auth -- **************************************************************************
        {
            path: "/merchant-login",
            redirect: "/merchant-login",
            component: AuthContainer,
            children: [
                // {
                //   path: '/merchant-login1',
                //   name: 'Merchant login',
                //   component: MerchantLogin
                // },
                {
                    path: "/merchant-register",
                    name: "Merchant register",
                    component: MerchantRegister,
                },
                {
                    path: "/merchant-otp",
                    name: "Merchant OTP",
                    component: MerchantOTP,
                    props: true,
                },
                // {
                //   path: '/merchant-landing-new',
                //   name: 'Merchant Landing',
                //   component: MerchantLanding
                // },
                {
                    path: "/test-page",
                    name: "Test Page",
                    component: TestPage,
                },
                {
                    path: "/password-reset",
                    name: "Password Reset",
                    component: PasswordReset,
                },
            ],
        },
        {
            path: "/",
            redirect: "/merchant-landing-new",
            component: ContainerWithOnlyNavBar,
            children: [{
                    path: "/merchant-register-v1",
                    name: "Merchant registerV1",
                    component: MerchantRegisterV1,
                },
                {
                    path: "/deliver-new-login",
                    name: "Delivery Partner new login",
                    component: DeliverNewLogin,
                },
                {
                    path: "/merchant-landing-new",
                    name: "Merchant Landing New",
                    component: MerchantLandingNew,
                },
                {
                    path: "/reset-password-merchant-portal",
                    name: "Reset Password Merchant Portal",
                    component: ResetPasswordFirstPage2,
                },
                {
                    path: "/merchant-contact-us",
                    name: "Merchant Contact Us",
                    component: ContactUs,
                },
                {
                    path: "/merchant-about-us",
                    name: "Merchant About Us",
                    component: AboutUs,
                },
                // {
                //   path: "/reset-password-merchant-portal-new",
                //   name: "Reset Password Merchant Portal New",
                //   component: ResetPasswordFirstPage2New,
                // },
                {
                    path: "/merchant-success",
                    name: "Merchant Success",
                    component: MerchantSuccess,
                }
            ],
        },
        {
            path: "/merchant-reg-Step2",
            redirect: "/merchant-reg-Step2",
            component: ContainerForMerchantRegistraion,
            children: [{
                    path: "/merchant-reg-Step2",
                    name: "Merchant Register Step2",
                    component: MerchantRegisterStep2,
                },
                {
                    path: "/merchantprof-mobile-verify",
                    name: "Merchant Profile Mobile Verify",
                    component: MerchantProfileMobileVerify,
                },
                {
                    path: "/merchant-guidelines",
                    name: "Merchant Guidelines",
                    component: MerchantGuidelines,
                },
            ],
        },
        // -- Delivery Routs -- **************************************************************************
        {
            path: "/deliver-dashbord",
            redirect: "/deliver-dashbord",
            component: DefaultContainerDeliver,
            children: [{
                    path: "/deliver-profile",
                    name: "Delivery Profile",
                    component: DeliverProfileConfigure,
                },
                {
                    path: "/deliver-dashbord",
                    name: "Delivery Dashbord",
                    component: DeliverDashbord,
                },
                {
                    path: "/deliver-rates",
                    name: "Delivery Rates",
                    component: DeliverRates,
                },
                {
                    path: "/deliver-pickup-address",
                    name: "Pickup Address",
                    component: PiskupAddressList,
                },
                {
                    path: "/deliver-pickup-address-submit/:id",
                    name: "Pickup Address Create",
                    component: PickupAddressCreate,
                },
                {
                    path: "/deliver-rate-configure/:id",
                    name: "Delivery Rate Configure",
                    component: DeliverRateConfigureNew,
                },
                // {
                //   path: "/deliver-rate-configure-new/:id",
                //   name: "Deliver Rate Configure New",
                //   component: DeliverRateConfigureNew,
                // },
                {
                    path: "/deliver-zone-list",
                    name: "Deliver Zone List",
                    component: DeliverZoneList,
                },
                {
                    path: "/deliver-order-list",
                    name: "Delivery Order List",
                    component: DeliverOrderList,
                },
                {
                    path: "/deliver-order-group",
                    name: "Delivery Order List Group",
                    component: DeliverOrderListGroup,
                },
                {
                    path: "/deliver-order-lines",
                    name: "Delivery Order Lines",
                    component: DeliverOrderLines,
                },
                {
                    path: "/deliver-order-details/:id",
                    name: "Delivery Order Details",
                    component: DeliverOrderDetails,
                },
                {
                    path: "/deliver-return-orders",
                    name: "Deliver Return Orders",
                    component: DeliverReturnOrders,
                },
                {
                    path: "/deliver-return-orders-configure/:id",
                    name: "Deliver Return Orders Configure",
                    component: DeliverReturnOrdersConfigure,
                },
                {
                    path: "/deliver-dispatch",
                    name: "Deliver Dispatch",
                    component: DeliverDispatch,
                },
                {
                    path: "/deliver-dispatch-city",
                    name: "Deliver Dispatch City",
                    component: DeliveryDispatchConfigure,
                },
            ],
        },
        // -- Delivery Partner Auth -- **************************************************************************
        {
            path: "/deliver-login",
            redirect: "/deliver-login",
            component: AuthDeliverContainer,
            children: [{
                    path: "/deliver-login",
                    name: "Delivery Partner login",
                    component: deliverLogin,
                },
                {
                    path: "/deliver-register",
                    name: "Delivery Partner register",
                    component: deliverRegister,
                },
                {
                    path: "/deliver-otp",
                    name: "Deliver OTP",
                    component: DeliverOtp,
                },
            ],
        },
        {
            path: "/reset-pass",
            redirect: "/reset-pass",
            component: ContainerForMessages,
            children: [{
                    path: "/reset-password",
                    name: "buyer reset password",
                    component: ResetPasswordFirstPage,
                },
                // {
                //   path: "/reset-password-merchant-portal",
                //   name: "Order Lines",
                //   component: ResetPasswordFirstPage2,
                // },
                {
                    path: "/reset-password-1",
                    name: "deliver reset password",
                    component: ResetPasswordFirstPage3,
                },
            ],
        },
        // -- Sucsess page and error page -- ********************************************************************
        {
            path: "/",
            redirect: "/",
            component: ContainerForMessages,
            children: [{
                    path: "/success-page",
                    name: "Success Page",
                    component: SuccessPage,
                },
                {
                    path: "/error-page",
                    name: "Error Page",
                    component: ErrorPage,
                },
                {
                    path: "/delivery-success",
                    name: "Delivery Success",
                    component: DeliverySuccess,
                },
            ],
        },
    ],
});