import Domain from "../domain";
export default {
    methods: {
        // authLogin: async function(obj) {
        //   try {
        //     let response = await this.axios.post(
        //       Domain.APP_DOMAIN + "admin/auth/login",
        //       obj,
        //       Domain.APP_BUYER_HEADER
        //     );
        //     return response;
        //   } catch (error) {
        //     throw error;
        //   }
        // },
        buyerMobileNoChange: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/profile/new/mobile/otp",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        buyerMobileNoChangeVerify: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/profile/new/mobile/otp/verify",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        postBuyerRestPasswordGetOtp: async function(obj) {
            try {
                let response = await this
                    .axios
                    .post(Domain.APP_DOMAIN + "portal/auth/forgot/password/otp", obj);
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        buyerOtpPwresetVerify: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/forgot/password/sothosa",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        forgetPasswordSetFunctionBuyer: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/forgot/password/set",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        buyerRegister: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/register",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        buyerMobileReset: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/new/mobile/otp",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        buyerMobileResetSathosa: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/new/mobile/otp/sathosa",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        buyerMobileVerify: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/verify/mobile",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        buyerMobileResend: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/auth/otp/resend/" + id + "?user_type=2",
                    Domain.APP_MERCHANT_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        putBuyerMobileOTPremain: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/auth/check/time", obj,
                    Domain.APP_MERCHANT_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        buyerLogin: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/login",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        firebaseLogin: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "common/oauth/login",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response;
            } catch (error) {
                throw error.response.data;
            }
        }
    }
};