export default [
  {
    name: 'Profile',
    icon: 'nc-icon nc-bank',
    path: '/buyer-profile'
  },
  {
    name: 'My Orders',
    icon: 'nc-icon nc-bank',
    path: '/buyer-orders',
    subpaths: ['Buyer Order Configure']
  },
  {
    name: 'Wishlist',
    icon: 'nc-icon nc-bank',
    path: '/buyer-wishlist'
  },
  {
    name: "Dispute & Return",
    icon: 'nc-icon nc-bank',
    path: '/buyer-return-orders',
    subpaths: ['Buyer Return Order Configure View']
  },
  {
    name: 'My Reviews',
    icon: 'nc-icon nc-bank',
    path: '/buyer-my-reviews'
  },
  {
    name: "My Coupons",
    icon: "nc-icon nc-bank",
    path: "/buyer-coupons",
  },
  {
    name: "My Vouchers",
    icon: 'nc-icon nc-bank',
    path: '/buyer-vouchers',
  }
  ]
