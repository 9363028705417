import Vue from 'vue'
import Vuex from 'vuex'

import breadcrumb from "./breadcrumb";
import cart from "./store/cart";
import buyer_basic_info from "./store/buyer_basic_info";
import storeManagement from "./store/storeManagement"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        breadcrumb: breadcrumb,
        storeManagement: storeManagement,
        buyer_basic_info: buyer_basic_info,
        cart: cart,
        merchant_accessToken: null,
        merchant_loggingIn: false,
        merchant_loginError: null,
        buyer_accessToken: null,
        buyer_loggingIn: false,
        buyer_loginError: null,
        globalcomponentdata: null,
        merchant_selectedstore: null,
        deliver_accessToken: null,
        deliver_loggingIn: false,
        deliver_loginError: null,
        filterdata: null,
        buyer_full_name: null,
        merchant_full_name: null,
        delivery_full_name: null,
        pickup_value_delivery: null,
        deliverystage: null,
        category_name: null,
        reset_pass: null,
        product_obj: null,
        fetchStoreMerchant_pId: null,
        merchant_dashboard_status: null,
        colourLogo: "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1631253195/cochchi/portal_user/1631253194042.png",
        whiteLogo: "https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1631253284/cochchi/portal_user/1631253283780.png"
    },
    mutations: {
        updateResetPassCondition: (state, value) => {
            state.reset_pass = value;
        },
        updateProductDetails: (state, value) => {
            state.product_obj = value;
        },
        updateCategoryName: (state, value) => {
            state.category_name = value;
        },
        updateMerchantName: (state, value) => {
            state.merchant_full_name = value;
        },
        updateDeliveryName: (state, value) => {
            state.delivery_full_name = value;
        },
        updateBuyerName: (state, value) => {
            state.buyer_full_name = value;
        },
        updatePickUpdelivery: (state, value) => {
            state.pickup_value_delivery = value;
        },
        updatedeliverystage: (state, value) => {
            state.deliverystage = value;
        },
        setBreadcrumbSwitchState(state, status) {
            state.breadcrumb.breadcrumb = {
                action: status.action,
                payload: status.payload
            };
        },
        setActiveStore(state, status) {
            state.storeManagement.activeList = {
                action: status.action,
                payload: status.payload
            };
        },
        setBuyerBasicInfo: (state, status) => {
            state.buyer_basic_info.buyer_basic_info = {
                action: status.action,
                payload: status.payload
            };
        },
        showPropertyWindow(state, status) {
            state.propertyAction.property = {
                action: status.action,
                payload: status.payload
            };
        },
        setStoreConfigs(state) {
            state.appConfig = (configs.APP_CONFIGS || {}).configuration || {};
        },
        setSelectedMembers(state, status) {
            state.members.selectedList = {
                action: status.action,
                payload: status.payload
            };
        },
        setViewModel(state, status) {
            state.members.modalView = {
                action: status.action,
                payload: status.payload
            };
        },
        loginMerchantStart: state => (state.merchant_loggingIn = true),
        loginMerchantStop: (state, errorMessage) => {
            state.merchant_loggingIn = false;
            state.merchant_loginError = errorMessage;
        },
        updateMerchantAccessToken: (state, merchant_accessToken) => {
            state.merchant_accessToken = merchant_accessToken;
        },
        logoutMerchant: state => {
            state.merchant_accessToken = null;
        },
        logindeliverStart: state => (state.deliver_loggingIn = true),
        logindeliverStop: (state, errorMessage) => {
            state.deliver_loggingIn = false;
            state.deliver_loginError = errorMessage;
        },
        updatedeliverAccessToken: (state, deliver_accessToken) => {
            state.deliver_accessToken = deliver_accessToken;
        },
        logoutdeliver: state => {
            state.deliver_accessToken = null;
        },
        loginbuyerStart: state => (state.buyer_loggingIn = true),
        loginbuyerStop: (state, errorMessage) => {
            state.buyer_loggingIn = false;
            state.buyer_loginError = errorMessage;
        },
        updatebuyerAccessToken: (state, buyer_accessToken) => {
            state.buyer_accessToken = buyer_accessToken;
        },
        logoutbuyer: state => {
            state.buyer_accessToken = null;
        },
        updateGlobalComponentData: (state, GlobalComponentData) => {
            state.globalcomponentdata = GlobalComponentData;
        },
        updateMerchant_selectedstore: (state, merchant_selectedstore) => {
            state.merchant_selectedstore = merchant_selectedstore;
        },
        updateMerchant_pId: (state, merchant_pId) => {
            state.fetchStoreMerchant_pId = merchant_pId;
        },
        setAddToCartSwitchState(state, status) { // -- product click add to cart --
            state.cart.cart = {
                action: status.action,
                payload: status.payload
            };
        },
        updateFilterData: (state, filterdata) => {
            state.filterdata = filterdata;
        },
    },
    getters: {},
    actions: {
        doLoginMerchant({ commit }) {
            commit("loginMerchantStart");
            if (localStorage.merchant_token) {
                commit("loginMerchantStop", null);
                commit("updateMerchantAccessToken", localStorage.merchant_token);
            } else {
                commit("loginMerchantStop", "Login Fail");
                commit("updateMerchantAccessToken", null);
            }
        },
        fetchMerchantAccessToken({ commit }) {
            commit("updateMerchantAccessToken", localStorage.getItem("merchant_token"));
        },
        logoutMerchant({ commit }) {
            localStorage.removeItem("merchant_token");
            commit("logoutMerchant");
        },
        doLogindeliver({ commit }) {
            commit("logindeliverStart");
            if (localStorage.deliver_token) {
                commit("logindeliverStop", null);
                commit("updatedeliverAccessToken", localStorage.deliver_token);
            } else {
                commit("logindeliverStop", "Login Fail");
                commit("updatedeliverAccessToken", null);
            }
        },
        fetchdeliverAccessToken({ commit }) {
            commit("updatedeliverAccessToken", localStorage.getItem("deliver_token"));
        },
        logoutdeliver({ commit }) {
            localStorage.removeItem("deliver_token");
            commit("logoutdeliver");
        },
        doLoginbuyer({ commit }) {
            commit("loginbuyerStart");
            if (localStorage.buyer_token) {
                commit("loginbuyerStop", null);
                commit("updatebuyerAccessToken", localStorage.buyer_token);
            } else {
                commit("loginbuyerStop", "Login Fail");
                commit("updatebuyerAccessToken", null);
            }
        },
        fetchbuyerAccessToken({ commit }) {
            commit("updatebuyerAccessToken", localStorage.getItem("buyer_token"));
        },
        logoutbuyer({ commit }) {
            localStorage.removeItem("buyer_token");
            commit("logoutbuyer");
        },
        fetchMerchant_selectedstore({ commit }) {
            commit("updateMerchant_selectedstore", localStorage.getItem('merchant_selectedstore'));
        },
        fetchStoreMerchant_pId({ commit }) {
            commit("updateMerchant_pId", localStorage.getItem('store_manager_pId'));
        },
        fetchGlobalComponentData({ commit }) {
            commit("updateGlobalComponentData", JSON.parse(localStorage.getItem('GlobalComponentData')));
        },
        fetchFilterData({ commit }) {
            commit("updateFilterData", JSON.parse(localStorage.getItem('filterdata')));
        },
    }
})