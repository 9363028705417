let Domain = Object.freeze({
    cochchi_test: "http://3.136.49.247:3063/api/v1/",
    cochchi_live: "http://124.43.130.172:3063/api/v1/",
    slt_cochchi_live: "https://api.cochchi.lk/api/v1/",
    slt_cochchi_local: "http://localhost:3063/api/v1/",
});
export const apiDomain = Domain.slt_cochchi_live;

export default {
    APP_DOMAIN: apiDomain,
    get APP_BUYER_HEADER() {
        const AuthStr = 'Bearer '.concat(localStorage.buyer_token);
        return {
            headers: {
                Authorization: AuthStr,
            },
        };
    },
    get APP_BUYER_HEADER_FORM_DATA() {
        const AuthStr = 'Bearer '.concat(localStorage.buyer_token);
        return {
            headers: {
                Authorization: AuthStr,
                'Content-Type': 'multipart/form-data',
            },
        };
    },
    get APP_MERCHANT_HEADER() {
        const AuthStr = 'Bearer '.concat(localStorage.merchant_token);
        return {
            headers: {
                Authorization: AuthStr,
            },
        };
    },
    get APP_MERCHANT_HEADER_FORM_DATA() {
        const AuthStr = 'Bearer '.concat(localStorage.merchant_token);
        return {
            headers: {
                Authorization: AuthStr,
                'Content-Type': 'multipart/form-data',
            },
        };
    },
    get APP_DELIVER_HEADER() {
        const AuthStr = 'Bearer '.concat(localStorage.getItem('deliver_token'));
        return {
            headers: {
                Authorization: AuthStr,
            },
        };
    },
    get APP_DELIVER_HEADER_FORM_DATA() {
        const AuthStr = 'Bearer '.concat(localStorage.getItem('deliver_token'));
        return {
            headers: {
                Authorization: AuthStr,
                'Content-Type': 'multipart/form-data',
            },
        };
    },
};