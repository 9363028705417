<template>
<div>
  <div class="row" style="margin-top:50px;">
    <div id="content" class="col-sm-12">
      <div class="page-login">
      
        <div class="account-border">
          <div class="row">
            <div class="col-sm-6 new-customer">
              <div class="well">
                <h2><i class="fa fa-file-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; New Customer</h2>
                <p class="loginPara">By creating an account you will be able to shop faster, be up to date on an order's status, and keep track of the orders you have previously made.</p>
              </div>
              <div class="bottom-form">
                <a @click="register" class="btn btn-default pull-right">Continue</a>
              </div>
            </div>
            
            <form @submit="checkForm">
              <div class="col-sm-6 customer-login">
                <div class="well">
                  <h2><i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; Returning Customer</h2>
                  <p><strong>I am a returning customer</strong></p>
                  <div class="form-group">
                    <label class="control-label " for="input-email">E-Mail Address</label>
                    <input type="text" required v-model="form.username" name="email" value="" id="input-email" class="form-control">
                  </div>
                  <div class="form-group">
                    <label class="control-label " for="input-password">Password</label>
                    <input type="password"  required v-model="form.password"  name="password" value="" id="input-password" class="form-control">
                  </div>
                </div>
                <div class="bottom-form">
                  <a href="#" class="forgot">Forgotten Password</a>
                  <input type="submit" value="Login" class="btn btn-default pull-right">
                </div>
                <!-- <div class="bottom-form">
                  <span class="pull-right" style="font-size:30px;">
                    <button type="button" title="" class="btn btn-success btn-google" style="margin-right: 10px;" @click=loginWithFirebasePopup(1)>
                      <i class="fa fa-google login-google" style="font-size:20px; display: flex; flex: 1;"></i>
                      <span class="login-text">Login with Google</span>
                    </button>
                    <button type="button" title="" class="btn btn-facebook" @click=loginWithFirebasePopup(2)>
                      <i class="fa fa-facebook login-google" style="font-size:20px; display: flex; flex: 1;"></i>
                      <span class="login-text">Login with Facebook</span>
                    </button>
                  </span>
                </div> -->
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
  </div>   
</div>
</template>
<script>
// Firebase Auth
import firebase from 'firebase';

import mixinAuth from "@/mixins/buyer/APIAuth";
export default {
  name: 'BuyerLogin',
  mixins: [mixinAuth],
  data(){
      return{
        form: {
          username: "",
          password: ""
        }
      }
  },
  watch:{
    $route (to, from){
        this.menuUnLock(); 
    },
    $store (){
        this.menuUnLock(); 
    }
  },
  created(){
    this.menuUnLock(); 
  },
  methods:{
    menuUnLock(){
        if(document.body.classList.contains('common-home')){ 
            document.querySelector('body').classList.remove('common-home');
        }
    },
    register(){
      this.$router.push({ name: "buyer register" });
    },
    handleContinue: function() {
      this.$router.push({ name: "Buyer Home" });
    },
    checkForm: async function (e) {   
      e.preventDefault();

      try {
          let response = await this.buyerLogin(this.form); 
          if (response.status === 200) {
            localStorage.setItem("buyer_token", response.data.token);
            localStorage.setItem("buyer_type", 'buyer');
            localStorage.setItem("login_buyer", response.data._id);
            localStorage.setItem("buyer_data", JSON.stringify(response.data));
            this.$store.dispatch("doLoginbuyer");
            this.$swal.fire({
                  position: 'top-right',
                  type: 'success',
                  title: "Logged In!",
                  showConfirmButton: false,
                  timer: 1500
                })
            this.handleContinue();
          }
            if(response.data._id !== ""){
              this.$swal.fire({
                  position: 'center',
                  type: 'success',
                  title: "Logged In!",
                  showConfirmButton: false,
                  timer: 1500
                })
            }
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }

    },
    // function firebase - login with firebase popup 
    loginWithFirebasePopup: function(authProvider){ 
      let self = this; 
      if(authProvider==1){var provider = new firebase.auth.GoogleAuthProvider();}
      else if(authProvider==2){var provider = new firebase.auth.FacebookAuthProvider();}
      firebase.auth().signInWithPopup(provider).then(function(result) { 
        firebase.auth().currentUser.getIdToken(true).then(function(idToken) { 
          self.loginWithFirebase(authProvider,idToken);
        }).catch(function(error) { alert('error.'); }); 
      }).catch(function(error) { 
        var errorCode = error.code;
        var errorMessage = error.message; 
        var email = error.email; 
        var credential = error.credential; 
      });
    },
    // login with farebase call mixin function 
    loginWithFirebase: async function(authProvider,idToken){
    try {
    let loginobj = { 
      "login_type": 1,
      "login_method": authProvider,
      "token": idToken
    }
    let response = await this.firebaseLogin(loginobj);
    if (response.status === 200) {
    localStorage.setItem("buyer_token", response.data.token);
    localStorage.setItem("buyer_type", 'buyer');
    localStorage.setItem("login_buyer", response.data._id);
    localStorage.setItem("buyer_data", JSON.stringify(response.data));
    this.$store.dispatch("doLoginbuyer");
    this.$swal.fire({
    position: 'top-right',
    type: 'success',
    title: "Logged In!",
    showConfirmButton: false,
    timer: 1500
    })
    this.handleContinue();
    }
    if(response.data._id !== ""){
    this.$swal.fire({
    position: 'center',
    type: 'success',
    title: "Logged In!",
    showConfirmButton: false,
    timer: 1500
    })
    }
    } catch (error) {
    this.$swal.fire({
    position: 'center',
    type: 'error',
    title: error.data.message,
    showConfirmButton: false,
    timer: 1500
    })      
    }
    }
  }
}
</script>
<style>
@media (min-width: 768px) {
  .loginPara {
    margin-bottom: 218px;
  }
}
@media (min-width: 1280px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1440px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1920px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 2880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
.login-google {
  font-size: 29px;
  margin-left: 0px;
  margin-right: 15px;
}
.btn-facebook {
  background-color: #29487d!important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-facebook:active, ._4jy1._42fs {
    background-color: #29487d;
    border-color: #29487d;
}

.btn-google {
  background-color:#d9534f!important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-google:hover {
    background-color:#cc514e!important;
    border-color: #ffff!important;
}
.btn-facebook:hover {
    background-color: #365899!important;
    border-color: #365899!important;
}
</style>