<template>
  <router-view />
</template>
<script>
import { async } from "q";

import sidebarLinksBuyer from "./sidebarLinksBuyer";
export default {
  name: "app",
  components: {
    sidebarLinksBuyer,
  },
  created() {
    this.getGlobalComponentData();
  },
  methods: {
    getGlobalComponentData: async function() {
      let globalcomdata = [
        {
          page: "Buyer Profile",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Orders",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Order Configure",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Return Orders",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Return Order Configure View",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Return Order Configure",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Wishlist",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer My Reviews",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Coupon List",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Voucher List",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer News Feed",
          leftnav: [
            {
              type: "MyAccountLeftCategories",
              data: { title: "My Account", linksArray: sidebarLinksBuyer },
            },
          ],
        },
        {
          page: "Buyer Home",
          leftnav: [
            //     {
            //     type: "AdvertSingleImage",
            //     data:  {title: "Categories", imgpath: "assets/image/catalog/banners/banner-sidebar.png", url: "#"}
            // }
          ],
          blocks: [
            {
              title: "Trending Products",
              blockcompnent: "ProductSliderBlock",
              blockdata: [
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/f5.jpg",
                    "assets/image/catalog/demo/product/270/f6.jpg",
                  ],
                  title: "Lenovo Yoga 2",
                  rating: "2",
                  price: "85",
                  discountprecentage: 12,
                  prediscount: "96",
                },
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/e10.jpg",
                    "assets/image/catalog/demo/product/270/e10.jpg",
                  ],
                  title: "Smart Watch",
                  rating: "3",
                  price: "85",
                  discountprecentage: 15,
                  prediscount: "100",
                },
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/e2.jpg",
                    "assets/image/catalog/demo/product/270/e2.jpg",
                  ],
                  title: "IPad Pro",
                  rating: "5",
                  price: "85000",
                  discountprecentage: 15,
                  prediscount: "100000",
                },
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/e4.jpg",
                    "assets/image/catalog/demo/product/270/e4.jpg",
                  ],
                  title: "Samsung Curve TV",
                  rating: "4",
                  price: "350000",
                  discountprecentage: 5,
                  prediscount: "400000",
                },
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/e8.jpg",
                    "assets/image/catalog/demo/product/270/e8.jpg",
                  ],
                  title: "DSLR Nikon D5100",
                  rating: "4",
                  price: "250000",
                  discountprecentage: 40,
                  prediscount: "350000",
                },
                {
                  blockdatacomponent: "ProductTile",
                  item_id: "3123123312sdsasd232",
                  url: "/product",
                  imgpath: [
                    "assets/image/catalog/demo/product/270/e5.jpg",
                    "assets/image/catalog/demo/product/270/e5.jpg",
                  ],
                  title: "Samsung Speaker",
                  rating: "3",
                  price: "20000",
                  discountprecentage: 50,
                  prediscount: "40000",
                },
              ],
            },
            // {
            //   blockcompnent: "Banner3",
            //   blockdata: [{
            //     class: "item1",
            //     item_id: "3123123312sdsasd232",
            //     url: "/product",
            //     imgpath:"assets/image/catalog/banners/banner3.jpg"
            //   },{
            //     class: "item2",
            //     item_id: "3123123312sdsasd232",
            //     url: "/product",
            //     imgpath:"assets/image/catalog/banners/banner4.jpg"
            //   },
            //   {
            //     class: "item3",
            //     item_id: "3123123312sdsasd232",
            //     url: "/product",
            //     imgpath:"assets/image/catalog/banners/banner5.jpg"
            //   }]
            // },
            // {
            //   blockcompnent: "Banner4",
            //   blockdata: [{
            //     class: "item1",
            //     item_id: "3123123312sdsasd232",
            //     url: "/product",
            //     imgpath:"assets/image/catalog/banners/bn1.jpg"
            //   },{
            //     class: "item2",
            //     item_id: "3123123312sdsasd232",
            //     url: "/product",
            //     imgpath:"assets/image/catalog/banners/bn2.jpg"
            //   }]
            // },
            // {
            //   title: "TRENDING CATEGORIES",
            //   blockcompnent: "ProductSliderGridBlock",
            //   blockdata: [{
            //     blockdatacomponent: "ProductTileGrid",
            //     item_id: "3123123312sdsasd232",
            //     url: "/productlist",
            //     imgpath:["assets/image/catalog/demo/product/270/e6.jpg", "assets/image/catalog/demo/product/270/2013-11-19-product-4.jpg", "assets/image/catalog/demo/product/270/yoga-2-pro_5_1160-100160506-orig.png"],
            //     title: "Laptops",
            //   },{
            //     blockdatacomponent: "ProductTileGrid",
            //     item_id: "3123123312sdsasd232",
            //     url: "/productlist",
            //     imgpath:["assets/image/catalog/demo/product/270/e1.jpg", "assets/image/catalog/demo/product/270/e3.jpg", "assets/image/catalog/demo/product/270/e2.jpg"],
            //     title: "Electronics",
            //   },
            //   {
            //     blockdatacomponent: "ProductTileGrid",
            //     item_id: "3123123312sdsasd232",
            //     url: "/productlist",
            //     imgpath:["assets/image/catalog/demo/product/270/e6.jpg", "assets/image/catalog/demo/product/270/2013-11-19-product-4.jpg", "assets/image/catalog/demo/product/270/yoga-2-pro_5_1160-100160506-orig.png"],
            //     title: "Laptops",
            //   },{
            //     blockdatacomponent: "ProductTileGrid",
            //     item_id: "3123123312sdsasd232",
            //     url: "/productlist",
            //     imgpath:["assets/image/catalog/demo/product/270/e1.jpg", "assets/image/catalog/demo/product/270/e3.jpg", "assets/image/catalog/demo/product/270/e2.jpg"],
            //     title: "Electronics",
            //   }]
            // },
          ],
        },
        {
          page: "product",
          leftnav: [],
        },
        {
          page: "Product List",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
                style: "margin-top: 430px;",
              },
            },
            // {
            //   type: "AdvertSingleImage",
            //   data: {
            //     title: "Categories",
            //     imgpath: "assets/image/catalog/banners/banner-sidebar.png",
            //     url: "#"
            //   }
            // }
          ],
        },
        {
          page: "Product List All",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
                style: "margin-top: 430px;",
              },
            },
            // {
            //   type: "AdvertSingleImage",
            //   data: {
            //     title: "Categories",
            //     imgpath: "assets/image/catalog/banners/banner-sidebar.png",
            //     url: "#"
            //   }
            // }
          ],
        },
        {
          page: "Product List Main",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
                style: "margin-top: 430px;",
              },
            },
            // {
            //   type: "AdvertSingleImage",
            //   data: {
            //     title: "Categories",
            //     imgpath: "assets/image/catalog/banners/banner-sidebar.png",
            //     url: "#"
            //   }
            // }
          ],
        },
        {
          page: "Brand Items",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
              },
            },
            {
              type: "AdvertSingleImage",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
              },
            },
          ],
        },
        {
          page: "Store Product",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
              },
            },
            // {
            //   type: "AdvertSingleImage",
            //   data: {
            //     title: "Categories",
            //     imgpath: "assets/image/catalog/banners/banner-sidebar.png",
            //     url: "#",
            //   },
            // },
          ],
        },
        {
          page: "E Teleshop",
          leftnav: [
            {
              type: "ProductFilters",
              data: {
                title: "Categories",
                imgpath: "assets/image/catalog/banners/banner-sidebar.png",
                url: "#",
              },
            },
            // {
            //   type: "AdvertSingleImage",
            //   data: {
            //     title: "Categories",
            //     imgpath: "assets/image/catalog/banners/banner-sidebar.png",
            //     url: "#",
            //   },
            // },
          ],
        }
      ];

      localStorage.setItem(
        "GlobalComponentData",
        JSON.stringify(globalcomdata)
      );

      this.$store.dispatch("fetchGlobalComponentData");
    },
  },
};
</script>
