import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SideBar from './components/Merchant/SidebarPlugin'

import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import Viewer from 'v-viewer'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueClipboard from 'vue-clipboard2'

// require styles
import 'swiper/dist/css/swiper.css'
import 'viewerjs/dist/viewer.css'

// Firebase Auth
import firebase from 'firebase';
import "firebase/analytics";
import "firebase/auth";

import Popper from 'vue-popperjs';


import VueMeta from 'vue-meta'
import IdleVue from 'idle-vue'
import ZoomOnHover from "vue-zoom-on-hover";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ProgressBar from 'vuejs-progress-bar'
import VueIziToast from 'vue-izitoast';
// import moment from 'vue-moment';
import 'izitoast/dist/css/iziToast.min.css';

// google analytics
import { onLCP, onFID, onCLS } from 'web-vitals';


Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
const eventsHub = new Vue()
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1800000
})

// socket io 
// import VueSocketIO from "vue-socket.io";

var SocialSharing = require('vue-social-sharing');

var firebaseConfig = {
    apiKey: "AIzaSyC20YDcbdiFNwwWK1a74NxFqMI0CXVmUDk",
    authDomain: "slt-cybermall.firebaseapp.com",
    databaseURL: "https://slt-cybermall.firebaseio.com",
    projectId: "slt-cybermall",
    storageBucket: "slt-cybermall.appspot.com",
    messagingSenderId: "275824902587",
    appId: "1:275824902587:web:3431001175394a58fe849e",
    measurementId: "G-B8KB26VXFD"
};
import infiniteScroll from 'vue-infinite-scroll'

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

function sendToAnalytics(metric) {
    const body = JSON.stringify(metric);
    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    (navigator.sendBeacon && navigator.sendBeacon('/analytics', body)) ||
    fetch('/analytics', { body, method: 'POST', keepalive: true });
}

onCLS(sendToAnalytics);
onFID(sendToAnalytics);
onLCP(sendToAnalytics);





Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);

Vue.use(VueAwesomeSwiper);
Vue.use(Viewer);
Vue.use(infiniteScroll);
Vue.use(VueClipboard);

Vue.use(SocialSharing);
Vue.use(Popper);
Vue.use(ZoomOnHover);
Vue.use(PulseLoader);
Vue.use(ProgressBar);
Vue.use(VueIziToast);
// Vue.use(moment);

import VueSocketio from 'vue-socket.io-extended';
import io from 'socket.io-client';

const { detect } = require('detect-browser');
const browser = detect();

// const socket = io('https://3.136.49.247:3003', {
//   autoConnect: false,
//   query: {token: '5d9ac4f45be61c4a8637c32a', token_type: 3}
// });

// Disables the automatic reconnection
// var socket = io('http://124.43.130.172:3063', {
var socket = io('https://api.cochchi.lk', {
    reconnection: true,
    autoConnect: false,
    query: { token: '5d9ac4f45be61c4a8637c32a', token_type: 3 }
});


Vue.use(VueSocketio, socket);

var reloadTime = 0;

// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: "http://3.136.49.247:3003/?token=5d9ac4f45be61c4a8637c32a&token_type=3",
//     transports: ["websocket", "polling", "flashsocket"],
//     autoConnect: false,
//     vuex: {
//       store,
//       actionPrefix: "SHSOCKET_",
//       mutationPrefix: "SHSOCKET_"
//     }
//   })
// );


// const options = { path: '/#/merchant-notification-center' }; //Options object to pass into SocketIO
// export const Socket = SocketIo(`http://localhost:8080`);
// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection:
//       "http://3.136.49.247:3003/?token=Bearer " +
//       localStorage.merchant_token,
//     transports: ["websocket", "polling", "flashsocket"],
//     vuex: {
//       store,
//       actionPrefix: "SHSOCKET_",
//       mutationPrefix: "SHSOCKET_"
//     },
//      options: { path: "/#/merchant-notification-center" }
//   }),
//   'http://localhost:8080'
// );

new Vue({
    router,
    store,
    components: {
        PulseLoader
    },
    watch: {
        $route: function() {
            this.$socket.client.disconnect(true);
            if (localStorage.GlobalComponentData) {
                store.dispatch("fetchGlobalComponentData");
            } else {
                this.$router.push("/login");
            }

            store.dispatch("fetchMerchantAccessToken");
            store.dispatch("fetchbuyerAccessToken");
            store.dispatch("fetchdeliverAccessToken");

            var merchantsection = this.$route.path.includes("merchant");
            var buyersection = this.$route.path.includes("buyer");
            var deliversection = this.$route.path.includes("deliver");

            if (merchantsection) {
                if (!store.state.merchant_accessToken) {
                    if (!["/merchant-landing-new", "/reset-password-merchant-portal", "/merchant-register", "/merchant-register-v1", "/merchant-reg-Step2", "/merchantprof-mobile-verify", "/merchant-guidelines", "/merchant-otp", "/merchant-dashbord", "/merchant-contact-us", "/merchant-about-us"].includes(this.$route.path)) {
                        this.$router.push("/merchant-landing-new");
                    }
                }
            }

            if (buyersection) {
                if (!store.state.buyer_accessToken) {
                    if (!["/buyer-landing", "/buyer-login", "/buyer-register", "/buyer-otp"].includes(this.$route.path)) {
                        this.$router.push("/buyerHome");
                    }
                }
            }

            if (deliversection) {
                if (!store.state.deliver_accessToken) {
                    if (!["/deliver-login", "/deliver-register", "/deliver-otp", "/deliver-dashbord"].includes(this.$route.path)) {
                        this.$router.push("/deliver-login");
                    }
                }
            }
            if (reloadTime === 0) {
                switch (browser && browser.name) {
                    case 'chrome':
                    case 'edge-chromium':
                    case 'edge':
                    case 'firefox':
                    case 'ios':
                    case 'safari':
                        console.log('supported');
                        break;

                    default:
                        console.log("browser", browser);
                        this.$swal.fire({
                            position: "center",
                            type: "warning",
                            title: "For Enhanced User Experience please use the following Web Browsers",
                            text: "Google Chrome, Microsoft Edge, Mozilla Firefox, Safari. Thank you.",
                            showConfirmButton: true,
                            confirmButtonText: 'Accept & Continue',
                        });
                }
                reloadTime = 1
            }

        }
    },
    render: function(h) { return h(App) }
}).$mount('#app')